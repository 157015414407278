import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedStoreState } from './shared-store.reducer';

export namespace SlSharedStoreSelectors {
  export const sharedStoreState =
    createFeatureSelector<SharedStoreState>('slSharedStore');

  export const badges = createSelector(
    sharedStoreState,
    (state) => state.badges,
  );
}
