import { createAction, props } from '@ngrx/store';
import { Badges } from '@sales-libs/order/data-access';

export namespace SlSharedStoreActions {
  // Badges
  export const GetBadges = createAction('[SL_SHARED_STORE] GET_BADGES');

  export const GetBadgesSuccess = createAction(
    '[SL_SHARED_STORE] GET_BADGES_SUCCESS',
    props<{ payload: Badges }>(),
  );
}
