import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '@sales-libs/order/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import { catchError, map, switchMap } from 'rxjs';
import { SlSharedStoreActions } from './shared-store.actions';

@Injectable()
export class SlSharedStoreEffects {
  private readonly actions$ = inject(Actions);

  getBadges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SlSharedStoreActions.GetBadges),
      switchMap(() =>
        this._orderService.getBadges().pipe(
          map((payload) => SlSharedStoreActions.GetBadgesSuccess({ payload })),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.order.badges',
              ),
            }),
          ]),
        ),
      ),
    ),
  );

  constructor(
    private readonly _orderService: OrderService,
    private readonly _translateService: TranslateService,
  ) {}
}
