import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScSharedState } from './sc-shared.store';
export namespace ScSharedSelectors {
  export const scSharedState =
    createFeatureSelector<ScSharedState>('ScSharedState');

  export const userGuides = createSelector(
    scSharedState,
    (state) => state?.userGuides?.data,
  );
  export const guide = createSelector(scSharedState, (state) => state?.guide);
  export const isLoading = createSelector(
    scSharedState,
    (state) => state?.isLoading,
  );
  export const productLineName = createSelector(
    scSharedState,
    (state) => state?.productLineName,
  );
  export const userGuid = createSelector(
    scSharedState,
    (state) => state?.guide?.user_guid,
  );
  export const currentSection = createSelector(
    scSharedState,
    (state) => state?.currentSection,
  );
  export const selectedLanguageCode = createSelector(
    scSharedState,
    (state) => state?.selectedLanguageCode,
  );
  export const languages = createSelector(
    scSharedState,
    (state) => state?.languages,
  );
  export const sections = createSelector(
    scSharedState,
    (state) => state?.sections,
  );
  export const generalMetadata = createSelector(
    scSharedState,
    (state) => state?.userGuides?.metadata,
  );
  export const modelSS = createSelector(scSharedState, (state) => [
    state?.modelImage3D,
  ]);
}
