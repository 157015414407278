import { createAction, props } from '@ngrx/store';
import { ProblemDetails, Utilization } from '@sales-libs/upselling/data-access';

export namespace SharedContractActions {
  export const createDefaultUtilizationForCart = createAction(
    '[Shared_Contract] CREATE_DEFAULT_UTILIZATION_FOR_CART',
    props<{ cartId: number }>(),
  );
  export const createDefaultUtilizationForCartSuccess = createAction(
    '[Shared_Contract] CREATE_DEFAULT_UTILIZATION_FOR_CART_SUCCESS',
    props<{ utilization: Utilization }>(),
  );
  export const createDefaultUtilizationForCartError = createAction(
    '[Shared_Contract] CREATE_DEFAULT_UTILIZATION_FOR_CART_ERROR',
    props<{ payload: ProblemDetails }>(),
  );
}
