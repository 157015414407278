import { Section } from '@sales-libs/sc-questionnaire/data-access';
import {
  Guide,
  Guides,
  Languages,
} from '@sales-libs/shared/data-access/sc-generated';

export interface ScSharedState {
  productLineName: string;
  userGuid: string;
  currentSection: Section | undefined;
  selectedLanguageCode: string;
  languages: Languages | undefined;
  isLoading: boolean;
  userGuides?: Guides;
  guide?: Guide;
  sections?: Section[];
  isPLSupported?: boolean;
  modelImage3D?: string;
}

export const initialState: ScSharedState = {
  productLineName: '',
  userGuid: '',
  currentSection: undefined,
  selectedLanguageCode: '',
  languages: undefined,
  isLoading: false,
  userGuides: undefined,
  guide: undefined,
  modelImage3D: undefined,
};
