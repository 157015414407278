import { Action, createReducer, on } from '@ngrx/store';
import { Badges } from '@sales-libs/order/data-access';
import { SlSharedStoreActions } from './shared-store.actions';

export interface SharedStoreState {
  badges?: Badges;
}

export const initialSharedState: SharedStoreState = {
  badges: undefined,
};

const reducer = createReducer(
  initialSharedState,
  on(SlSharedStoreActions.GetBadgesSuccess, (state, { payload }) => ({
    ...state,
    badges: payload,
  })),
);

export function slSharedStoreReducer(
  state: SharedStoreState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
